.header {
    margin-top : 50px;
}

.card {
    width: 75%;
    margin: auto;
}

.header-container{
    position: relative;
    text-align: center;
    color: white;
    font-size: 50px;
    font-weight: bold;
}

.header-text{
    /*margin-top: 10px;*/
    /*padding: 20px;*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-text {
    padding-top: 30px;
}

.main-content {
    text-align: center;
}