.services-card {
    width: 50%;
    margin: auto;
}

.services-header {
    margin: auto;
    width: 65%;
    padding: 10px 0 10px 0;
}

.services-p {
    margin-top:15px;
}
