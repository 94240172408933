.about-header {
    margin: auto;
    width: 35%;
    padding: 10px;
}

.about-p {
    margin-top:15px;
}

.about-card {
    width: 50%;
    margin: auto;
}