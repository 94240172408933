.header {
    margin-top : 50px;
}

.card {
    width: 75%;
    margin: auto;
}

.header-container{
    position: relative;
    text-align: center;
    color: white;
    font-size: 50px;
    font-weight: bold;
}

.header-text{
    /*margin-top: 10px;*/
    /*padding: 20px;*/
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.home-text {
    padding-top: 30px;
}

.main-content {
    text-align: center;
}
.about-header {
    margin: auto;
    width: 35%;
    padding: 10px;
}

.about-p {
    margin-top:15px;
}

.about-card {
    width: 50%;
    margin: auto;
}
.services-card {
    width: 50%;
    margin: auto;
}

.services-header {
    margin: auto;
    width: 65%;
    padding: 10px 0 10px 0;
}

.services-p {
    margin-top:15px;
}

.page-header {
    margin-bottom: 50px;

}

.header-link {
	color: #c1c1c1;
	text-decoration: none;
}

.header-link:hover{
	color: #d3d3d3;
	text-decoration: none;
}

.background {
    height: 100vh;
}
