.page-header {
    margin-bottom: 50px;

}

.header-link {
	color: #c1c1c1;
	text-decoration: none;
}

.header-link:hover{
	color: #d3d3d3;
	text-decoration: none;
}